* {  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-x: hidden;
    

    &::-webkit-scrollbar {
        display: none;
    }
}

.app {
    min-height: 100%;
    background-color: #090915;
    opacity: 1;
    background: radial-gradient(
        circle,
        transparent 20%,
        #090915 20%,
        #090915 80%,
        transparent 80%,
        transparent
      ),
      radial-gradient(
          circle,
          transparent 20%,
          #090915 20%,
          #090915 80%,
          transparent 80%,
          transparent
        )
        40px 40px,
      linear-gradient(#101322 3.2px, transparent 3.2px) 0 -1.6px,
      linear-gradient(90deg, #101322 3.2px, #090915 3.2px) -1.6px 0;
    background-size: 80px 80px, 80px 80px, 40px 40px, 40px 40px;
}
